import React from 'react';
import cn from 'classnames';
import styles from './style.module.css';
import HomeIcon from './icons/home-icon';
import { NavLink, useMatches } from 'react-router-dom';
import { TAB_PATHS } from '../../../../contexts/TabContext';
import { useTranslation } from '../../../../contexts/LocaleContext';

export const Breadcrumbs = ({ className, children }) => {
  const { t } = useTranslation();
  const matches = useMatches();
  const crumbs = matches
    .filter((match) => Boolean(match.handle?.crumb))
    .map((match) => match.handle.crumb(match.params));

  return (
    <div className={cn(styles.wrapper, className)}>
      <div className={cn(styles.breadcrumbs)}>
        <NavLink className={cn(styles.link)} to={TAB_PATHS.COMPANY_DASHBOARD}>
          <button
            className={cn(styles.button, styles.buttonActive)}
            type="button"
          >
            <HomeIcon />
          </button>
        </NavLink>

        {crumbs.map(({ path, label }) => (
          <>
            <span className={cn(styles.divider)}>/</span>
            <NavLink className={cn(styles.link)} to={path} end>
              {({ isActive }) => (
                <button
                  className={cn(styles.button, {
                    [styles.buttonActive]: !isActive,
                  })}
                  type="button"
                >
                  {t(label)}
                </button>
              )}
            </NavLink>
          </>
        ))}
      </div>
    </div>
  );
};

export default Breadcrumbs;
