import { Form } from 'informed';
import styles from './style.module.css';
import Input from './Input';
import loginImagePath from './images/login.png';
import passwordImagePath from './images/password.png';
import Button from '../common/core/Button';
import React, { useContext, useEffect, useState } from 'react';
import InputPlaceholder from './InputPlaceholder';
import { Row, Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import cn from 'classnames';
import Checkbox from '../common/core/Checkbox';
import { useTranslation } from '../../contexts/LocaleContext';
import NetworkContext from '../../contexts/NetworkContext';

const termsUrl = 'https://fastboss.ai/terms-of-use/';
const policyUrl = 'https://fastboss.ai/privacy-policy/';

const SignUpForm = ({
  onSubmit,
  failed,
  agreeWithTermsAndPolicyHandler,
  agreeCheckboxValue,
  failText,
  queryEmail = '',
  waiting
}) => {
  const { t } = useTranslation();
  const networkContext = useContext(NetworkContext);

  return (
    <Form
      onSubmit={onSubmit}
      className={styles.form}
      style={{ marginTop: '-15px' }}
    >
      <Input
        field={'login'}
        placeholder={<InputPlaceholder text={t('login')} />}
        inputPlaceholder={t('Create login')}
        required
      />

      <Input
        field={'password'}
        type={'password'}
        placeholder={<InputPlaceholder text={t('password')} />}
        inputPlaceholder={t('Create password')}
        required
      />

      <Input
        field={'name'}
        placeholder={<InputPlaceholder text={t('name')} />}
        inputPlaceholder={t('Enter a name')}
        required
      />

      <Input
        field={'surname'}
        placeholder={<InputPlaceholder text={t('surname')} />}
        inputPlaceholder={t('Enter a surname')}
        required
      />

      <Input
        field={'workemail'}
        placeholder={<InputPlaceholder text={t('work_email')} />}
        required
        key={queryEmail ? `${Math.floor(Math.random() * 1000)}-min` : '1000'}
        initialValue={queryEmail}
        inputPlaceholder={t('Enter your email address')}
        disabled={queryEmail ? true : false}
      />

      <Input
        field={'telephone'}
        placeholder={<InputPlaceholder text={t('phone')} />}
        inputPlaceholder={t('Enter your phone number')}
        required
      />

      {!queryEmail && (
        <>
          <Input
            field={'companyName'}
            placeholder={<InputPlaceholder text={t('Company name')} />}
            inputPlaceholder={t('Enter your company name')}
            required
          />

          <Input
            field={'companyAddress'}
            placeholder={<InputPlaceholder text={t('Company address')} />}
            inputPlaceholder={t('Enter your company address')}
            required
          />
        </>
      )}

      <Col className={styles.checkboxLine} style={{ marginTop: '20px' }}>
        <Checkbox
          onChangeHandler={agreeWithTermsAndPolicyHandler}
          field={'agreeWithTermsAndPolicy'}
          value={agreeCheckboxValue}
        />
        <span className={styles.rememberText}>
          {t('agree_with')} &nbsp;
          <a
            href={termsUrl}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.agreeWith}
          >
            {t('terms')}
          </a>{' '}
          &nbsp;
          {t('and')} &nbsp;
          <a
            href={policyUrl}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.agreeWith}
          >
            {t('policy')}
          </a>
        </span>
      </Col>

      <Row className={styles.buttonContainer} style={{ marginTop: '10px' }}>
        <Button
          width="100%"
          height="36px"
          type={'submit'}
          title={t('register')}
          waiting={waiting}
          disabled={!agreeCheckboxValue}
          containerClassName={styles.button}
        />
      </Row>

      {failed && failText && (
        <div className={cn(styles.fail, failed && styles.failActive)}>
          {failed && failText}
        </div>
      )}
    </Form>
  );
};

export default SignUpForm;
