import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import styles from './style.module.css';
import Button from '../../core/Button';
import Card from '../Card';
import Search from '../Search';
import { useTranslation } from '../../../../contexts/LocaleContext';
import { useNavigate } from 'react-router-dom';
import { useRestriction } from '../../../../contexts/Restriction/RestrictionContext';
import InputSelect from '../../../SignIn/Input/InputSelect';
import InputPlaceholder from '../../../SignIn/InputPlaceholder';
import { useMediaQuery } from 'react-responsive';
import InputDate from '../../../SignIn/Input/InputDate';
import moment from 'moment';
import { CloseIcon } from '../../icons';
import { Form } from 'informed';
import { AssignTaskMembers } from '../../../Dashboard/contents/CompanyDashboard/screens/Tasks/ManageTaskAssignment ';
import { Text } from 'informed';
import CorporateContext from '../../../Dashboard/contents/CompanyDashboard/CorporateContext';

const Filters = ({
  className,
  filters,
  activeFilters,
  filtersSchema,
  setFilters,
  isFiltersOpen,
  setIsFiltersOpen,
  setActiveFilters,
}) => {
  const { t } = useTranslation();
  const { withRestrictions } = useRestriction();
  const navigate = useNavigate();
  const isLargeScreen = useMediaQuery({
    query: '(min-width: 1600px)',
  });

  const toggleOpen = () => setIsFiltersOpen((current) => !current);
  const onSave = (fields = {}) => {
    setIsFiltersOpen(false);
    const values = { ...activeFilters, ...fields };

    if (!Object.values(values).length) {
      onClear();
    } else {
      setActiveFilters({ ...values });
    }
  };
  const onAppend = (option) => {
    const filter = filtersSchema.find((current) => current.id === option.id);
    setFilters((current) => [...current, filter]);
  };
  const onClear = () => {
    setFilters([]);
    setActiveFilters({});
  };

  const schemaOptions = useMemo(
    () =>
      filtersSchema
        .filter((current) => !filters.some((i) => i.id === current.id))
        .map((current) => ({
          id: current.id,
          label: current.label,
        })),
    [filters, filtersSchema]
  );
  const filtersLimit = useMemo(() => (isLargeScreen ? 4 : 3), [isLargeScreen]);

  return (
    <div className={cn(styles.container, className)}>
      {!isFiltersOpen && (
        <div className={cn(styles.wrapper)}>
          <button
            className={cn(styles.filterButton)}
            type="button"
            onClick={toggleOpen}
          >
            <i className={cn(styles.filterButtonIcon, 'fas fa-filter')}></i>
          </button>

          {filters.length > 0 && (
            <Button
              className={cn(styles.clearButton)}
              containerClassName={cn(styles.clearButtonContainer)}
              width={141}
              height={36}
              color="#E8BAD5"
              title={
                <div className={cn(styles.clearButtonContent)}>
                  <span className={cn(styles.clearButtonText)}>
                    {t('Filters')}
                  </span>
                  <span className={cn(styles.clearButtonCounter)}>
                    {filters.length}
                  </span>
                  <CloseIcon className={cn(styles.clearButtonIcon)} />
                </div>
              }
              textColor="#B11873"
              onClick={onClear}
            />
          )}
        </div>
      )}

      {isFiltersOpen && (
        <Form className={cn(styles.wrapper)} onSubmit={onSave}>
          {({ formApi }) => (
            <>
              <div className={cn(styles.fields)}>
                {filters.map((current) => {
                  switch (current.type) {
                    case 'date':
                      return (
                        <InputDate
                          key={current.id}
                          forceDate
                          type={'date'}
                          field={current.field}
                          className={cn(styles.input, styles.inputDate)}
                          initialValue={
                            activeFilters[current.field] || moment().toDate()
                          }
                          placeholder={
                            <InputPlaceholder text={current.label} />
                          }
                          parse={(value) => {
                            return moment(value).format(
                              'YYYY-MM-DDTHH:00:00.000+00:00'
                            );
                          }}
                          format={(value) => moment(value).format('YYYY-MM-DD')}
                          max={'3000-01-01'}
                          minHeight={36}
                        />
                      );

                    case 'select':
                      return (
                        <InputSelect
                          key={current.id}
                          field={current.field}
                          formApi={formApi}
                          className={cn(styles.input, styles.inputSelect)}
                          initialValue={activeFilters[current.field]}
                          placeholder={
                            <InputPlaceholder text={current.label} />
                          }
                          inputPlaceholder={current.placeholder}
                          options={current.options}
                          minHeight={36}
                        />
                      );

                    case 'corporate_users':
                      return (
                        <CorporateUsersInput
                          key={current.id}
                          initialValue={activeFilters[current.field]}
                          inputProps={{
                            field: current.field,
                            formApi: formApi,
                            className: cn(styles.input, styles.inputSelect),
                            placeholder: (
                              <InputPlaceholder text={current.label} />
                            ),
                            inputPlaceholder: current.placeholder,
                            minHeight: 36,
                          }}
                        />
                      );

                    default:
                      return <></>;
                  }
                })}

                {filters.length < filtersLimit && (
                  <InputSelect
                    field={'select_filter'}
                    value=""
                    className={cn(styles.input)}
                    inputPlaceholder={t('Select filter')}
                    options={schemaOptions}
                    onChange={onAppend}
                    minHeight={36}
                  />
                )}
              </div>

              <Button
                className={cn(styles.saveButton)}
                containerClassName={cn(styles.saveButtonContainer)}
                width={125}
                height={36}
                color="#5ED615"
                title={<>{t('Save')}</>}
                onClick={formApi.submitForm}
              />
            </>
          )}
        </Form>
      )}
    </div>
  );
};

const CorporateUsersInput = ({ initialValue, inputProps = {}, ...props }) => {
  const { workers, departments } = useContext(CorporateContext);
  const [options, setOptions] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);

  const value = useMemo(
    () => selectedMembers.map((i) => i.id).join(','),
    [selectedMembers]
  );
  const label = useMemo(
    () => selectedMembers.map((i) => i.user_name).join(', '),
    [selectedMembers]
  );

  useState(() => {
    if (!initialValue || !workers.data || !departments.data) return;
    const ids = initialValue.split(',').map(Number);
    const owners =
      workers.data.filter((current) => current.department_id === 0);
    const employees = departments.data.reduce(
      (accumulator, department) => [
        ...accumulator,
        ...department.employees_department.map((employee) => ({
          ...employee,
          department_name: department.name,
        })),
      ],
      owners
    );
    const members = employees.filter((i) => ids.includes(i.id));
    setSelectedMembers(members);
  }, [initialValue, workers.data, departments.data]);

  useEffect(() => {
    if (!inputProps.formApi) return;
    inputProps.formApi.setValue(inputProps.field, value);
  }, [inputProps.formApi, inputProps.field, value]);

  return (
    <div className={cn(styles.selectCorporateUsers)}>
      <Text
        hidden
        field={inputProps.field}
        value="test"
        style={{ display: 'none' }}
      />
      <AssignTaskMembers
        options={(props) => setOptions(props)}
        setMembers={setSelectedMembers}
        ButtonElement={({ onClick }) => (
          <InputSelect
            {...inputProps}
            inputPlaceholder={label || inputProps.inputPlaceholder}
            value={value}
            onClick={onClick}
            options={[{ id: value, label }]}
            defaultValue={{ id: value, label }}
          />
        )}
        {...props}
      />
    </div>
  );
};

export default Filters;
