import React, { useState, useCallback, useMemo, forwardRef } from 'react';
import cn from 'classnames';
import { Text, TextArea, useField, useFieldApi } from 'informed';
import Select from 'react-select';
import styles from './style.module.css';

const InputSelect = forwardRef(
  (
    {
      textarea = false,
      select = false,
      placeholder,
      inputPlaceholder,
      required,
      initialValue = '',
      errorStyle,
      formApi,
      className,
      minHeight = 40,
      onClick,
      ...otherProps
    },
    ref
  ) => {
    const [value, setValue] = useState('');
    const [isPlaceholder, setPlaceholder] = useState(true);

    const onFocus = (e) => setPlaceholder(false);
    const onBlur = (e) => setPlaceholder(!e.target.value);
    const onChange = (option) => {
      if (!formApi) return;
      setValue(option.id);
      formApi.setValue(otherProps.field, option.id);
    };

    const defaultValue = useMemo(() => {
      return (
        otherProps.options.find((option) => option.id === initialValue) || ''
      );
    }, [initialValue, otherProps.options]);

    return (
      <div className={cn(className)} onClick={onClick}>
        {placeholder && (
          <div
            className={styles.placeholder}
            style={{ color: '#000', minHeight: 0 }}
          >
            <span className={styles.holderPlace}>
              {placeholder}
              <span className={styles.holderPlaceRequired}>
                {required ? '*' : ''}
              </span>
            </span>
          </div>
        )}
        <Text
          hidden
          field={otherProps.field}
          value={value}
          style={{ display: 'none' }}
        />
        <Select
          ref={ref}
          onFocus={onFocus}
          onBlur={onBlur}
          className={cn(styles.input, styles.select)}
          autoComplete={'off'}
          style={{ minHeight, height: '100%' }}
          required={required}
          isSearchable={false}
          defaultValue={defaultValue}
          placeholder={inputPlaceholder}
          menuPortalTarget={document.body}
          onChange={onChange}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              height: `${minHeight}px`,
              paddingLeft: '5px',
              borderColor: '#E7E7E8',
              boxShadow: 'none',
              '&:hover': {
                borderColor: '#E7E7E8',
              },
            }),
            indicatorSeparator: (baseStyles, state) => ({
              ...baseStyles,
              backgroundColor: '#E7E7E8',
            }),
            menuPortal: (baseStyles, state) => ({
              ...baseStyles,
              zIndex: 10,
            }),
            placeholder: (baseStyles, state) => ({
              ...baseStyles,
              color: '#83878B',
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '20px',
            }),
            option: (baseStyles, state) => ({
              ...baseStyles,
              width: 'calc(100% - 20px)',
              fontSize: '16px',
              lineHeight: '18px',
              margin: '6px 10px',
              borderRadius: '4px',
              backgroundColor: 'inherit',
              color: '#070F17',
              transition: 'all 100ms ease',
              '&:hover': {
                backgroundColor: state.isSelected ? '#B11873' : '#E8BAD5',
                cursor: 'pointer',
              },
              '&:active': {
                backgroundColor: state.isSelected ? '#B11873' : '#E8BAD5',
                cursor: 'pointer',
              },
            }),
            // singleValue: (baseStyles, state) => ({
            //   ...baseStyles,
            //   width: 'auto',
            //   color: 'white',
            //   backgroundColor: '#5ed615',
            //   fontSize: '12px',
            //   fontWeight: '500',
            //   lineHeight: '14px',
            //   borderRadius: '4px',
            //   letterSpacing: '0.4px',
            //   height: '16px',
            // }),
          }}
          {...otherProps}
        />
      </div>
    );
  }
);

export default InputSelect;
