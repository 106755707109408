import { Form } from 'informed';
import styles from './style.module.css';
import Input from './Input';
import loginImagePath from './images/login.png';
import passwordImagePath from './images/password.png';
import Button from '../common/core/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Checkbox from '../common/core/Checkbox';
import React from 'react';
import InputPlaceholder from './InputPlaceholder';
import cn from 'classnames';
import TouchableOpacity from '../common/core/TouchableOpacity';
import { useTranslation } from '../../contexts/LocaleContext';
import { GoogleIcon } from '../common/icons';

function SignInForm({
  onSubmit,
  failed,
  waiting,
  googleWaiting,
  failText,
  loginWithGoogle,
  openForgotPasswordModal,
}) {
  const { t } = useTranslation();
  return (
    <Form
      onSubmit={onSubmit}
      className={styles.form}
      style={{ marginTop: '-15px' }}
    >
      <Input
        field={'login'}
        placeholder={<InputPlaceholder text={t('login')} />}
        inputPlaceholder={t('Enter your login')}
        required
      />
      <Input
        field={'password'}
        type={'password'}
        placeholder={<InputPlaceholder text={t('password')} />}
        inputPlaceholder={t('Enter your password')}
        required
      />

      <Row className={'mb-1'} style={{ marginTop: '20px' }}>
        <Col className={styles.rememberContainer}>
          <Checkbox field={'remember'} />
          <span className={styles.rememberText}>{t('remember_me')}</span>
        </Col>
        <Col className={styles.forgotText}>
          <TouchableOpacity onClick={openForgotPasswordModal}>
            {t('forgot_password')}
          </TouchableOpacity>
        </Col>
      </Row>

      <div className={cn(styles.fail, failed && styles.failActive)}>
        {failed && failText}
      </div>

      <Row className={styles.buttonContainer}>
        <Button
          width="100%"
          height="36px"
          type={'submit'}
          waiting={waiting}
          className={styles.button}
          title={t('sign_in')}
        />
        <Button
          width="100%"
          height="36px"
          type={'button'}
          textColor={'#070f17'}
          waiting={googleWaiting}
          className={styles.buttonGoogle}
          containerClassName={styles.buttonGoogleContainer}
          title={t('Sign in with Google')}
          leftElement={<GoogleIcon className={styles.buttonGoogleIcon} />}
          onClick={loginWithGoogle}
        />
      </Row>
    </Form>
  );
}

export default SignInForm;
