import React, { useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate, useMatch } from 'react-router-dom';

import useVoiceIO from './useVoiceIO';
import { ReactComponent as PaperClip } from './icons/paperclip.svg';
import { repairVariables, useFields, addressToStr } from './variableFields';

import Enum from '../../../../utils/enum';
import DataContext, { useLoaded } from '../../../../contexts/DataContext';
import { formatDate, sortByIdDesc } from '../../../../utils/StringUtils';
import { useTranslation } from '../../../../contexts/LocaleContext';
import { useRestriction } from '../../../../contexts/Restriction/RestrictionContext';
import { sendAssistantSupportIssue } from '../../../../api/AssistantSupport/dataSetters';
import { POST } from '../../../../api/network';
import { Info } from '../../../common/core/Toasts';
import resizebase64 from '../../../../utils/base64resize';

const [ASSISTANT, USER] = Enum(2);
const [PARTNER, CUSTOMER, PRODUCT, SERVICE, TAG] = Enum(3);

const message = (
  text = '',
  sender = ASSISTANT,
  attachment = null,
  additional = {}
) => ({
  sender,
  text,
  attachment,
  ...additional,
});

const useAssistantDialog = () => {
  const { t } = useTranslation();
  const { player, recorder, initialized } = useVoiceIO();
  const [messages, setMessages] = useState([]);
  const [scheduledText, setScheduledText] = useState(null);

  useEffect(() => {
    if (scheduledText && initialized) {
      player.stop();
      player.play(scheduledText);
      setScheduledText(null);
    }
  }, [scheduledText, initialized]);

  const clear = (initialMessage) => {
    setMessages(initialMessage ? [message(initialMessage)] : []);
  };

  const addMessage = (
    _text,
    sender = ASSISTANT,
    attachment = null,
    silent = false,
    valuesLength = null,
    isGPT = false,
    additional = {}
  ) => {
    const enumerableText = valuesLength
      ? t(_text) + ' ' + valuesLength
      : t(_text);
    const text = sender === ASSISTANT && !isGPT ? enumerableText : _text;
    const messageNew = message(text, sender, attachment, additional);

    const isCopyOfLastMessage = messages.some(
      (current, index, array) =>
        index === array.length - 1 &&
        current.text === messageNew.text &&
        current.sender === messageNew.sender
    );
    if (isCopyOfLastMessage) {
      return;
    }

    setMessages((prev) => [...prev, messageNew]);

    if (sender !== ASSISTANT || silent) return;
    if (initialized) {
      player.stop();
      player.play(text);
    } else {
      setScheduledText(text);
    }
  };

  return { messages, clear, addMessage, recorder, player };
};

const useSupport = (suspend = false, dialogData, supportRequest = '') => {
  const [supportMessageSent, setSupportMessageSent] = useState(false);
  const { t } = useTranslation();
  const { account } = useContext(DataContext);
  const { messages, addMessage, recorder, player } = dialogData;
  const telephone = account.data.telephone;

  const [options, setOptions] = useState([]);
  const [screenshot, setScreenShot] = useState(null);
  const [issue, setIssue] = useState(supportRequest);
  const [step, setStep] = useState(1);
  const clip = (label) => {
    return (
      <label style={{ cursor: 'pointer', margin: 0 }}>
        {label}
        <PaperClip width="16" stroke="red" style={{ marginLeft: '10px' }} />
        <input
          name="input"
          type="file"
          style={{ display: 'none' }}
          encType="multipart/form-data"
          accept="image/*"
          onChange={getFiles}
        />
      </label>
    );
  };

  const getText = (text) => {
    if (step === 0) {
      setIssue(text);
      addMessage(text, USER);
      setStep(1);
      return;
    }
    if (step === 3) {
      setScreenShot((prev) => ({ ...prev, telephone: text }));
      addMessage(text, USER);
      sendData(issue, screenshot);
      return;
    }
  };

  const speachToText = (text) => {
    if (step === 0) {
      setIssue(text);
      addMessage(text, USER);
      setStep(1);
      return;
    }
    if (step === 3) {
      setScreenShot((prev) => ({ ...prev, telephone: text }));
      addMessage(text, USER);
      sendData(issue, screenshot);
      return;
    }
  };

  const getFiles = (event) => {
    const file = event.target.files[0];
    addMessage(file.name, USER);
    setScreenShot(file);
    setStep(2);
  };

  const sendData = async (issue, file) => {
    addMessage(
      t(
        'i_have_sent_your_issues_to_the_support_team_they_will_contact_you_shortly'
      ),
      ASSISTANT
    );
    setOptions([]);
    await sendAssistantSupportIssue(issue, file);
  };

  const switchOptions = (step) => {
    switch (step) {
      case 1:
        addMessage(t('Do_you_want_to_attach_some_screenshots'), ASSISTANT);
        setOptions([
          { label: clip(t('Yes,_I_do')) },
          { label: t('No,_I_do_not'), onPress: () => setStep(2) },
        ]);
        break;
      case 2:
        if (telephone) {
          sendData(issue, screenshot);
          setTimeout(() => setSupportMessageSent(true), 3000);
          break;
        }
        addMessage(t('Do you want to be contacted by phone'), ASSISTANT);
        setOptions([
          { label: t('Yes,_I_do'), onPress: () => setStep(3) },
          {
            label: t('No,_I_do_not'),
            onPress: () => {
              sendData(issue, screenshot);
              setTimeout(() => setSupportMessageSent(true), 3000);
            },
          },
        ]);
        break;
      case 3:
        addMessage(
          t(
            'please_enter_your_phone_number_in_the_following_format_country_code_your_number'
          ),
          ASSISTANT
        );
        setOptions([]);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (suspend) return;
    if (!issue) setIssue(supportRequest);
    switchOptions(step);
  }, [step, suspend]);

  return {
    messages: [...messages],
    send: getText,
    options,
    recorder,
    player,
    supportMessageSent,
  };
};

export const useTemplateFilling = (
  setScenario = () => {},
  initialTemplate = null,
  suspend = false,
  dialogData
) => {
  const navigate = useNavigate();
  const { messages, addMessage, recorder, player } = dialogData;

  const [isThinking, setThinking] = useState(true);
  const [isReady, setReady] = useState(false);
  const { isLoaded: templatesAreLoaded, templates } = useLoaded('templates');
  const { t } = useTranslation();
  const [options, setOptions] = useState([]);
  const [template, _setTemplate] = useState(null);
  // const [actions, setActions] = useState([])
  const { actions } = useFields(template?.content);
  const [values, setValues] = useState([]); // Array of {value: 'Goes into doc', index: Number}
  const [step, setStep] = useState(-1); // -1 = preparations, 0..N = fields
  const [manualState, setManualState] = useState(false); // In case value is not provided in data
  const [memory, setMemory] = useState({
    memorizedFields: {},
    selectedInstances: {},
  });
  const [nextIsSilent, setNextIsSilent] = useState(false);
  const { withRestrictions } = useRestriction();
  const [clientName, setClientName] = useState('');
  const [listItem, setListItem] = useState(null);

  const data = useContext(DataContext);

  const init = () => {
    setThinking(true);
    if (templatesAreLoaded) setThinking(false);
  };

  useEffect(() => {
    if (suspend) return;
    init();
  }, [templatesAreLoaded, suspend]);

  const setTemplate = (template, suspend) => {
    if (!suspend) addMessage(template?.title, USER);
    _setTemplate(template);
  };

  const setProtectedTemplate = withRestrictions(setTemplate);

  useEffect(() => {
    if (template && actions.length < 1) {
      addMessage(
        t('Your template has no variable fields. Please select other one.')
      );
      setOptions(
        templates.data.map((template) => ({
          label: template.title,
          id: template.id,
          onPress: () => setTemplate(template),
        }))
      );
    }
  }, [actions?.length, template]);

  const onStart = () => {
    if (initialTemplate) {
      setTemplate(initialTemplate, true);
    } else {
      addMessage(t('Which template do you want to use?'));
      setOptions(
        templates.data.map((template) => ({
          label: template.title,
          id: template.id,
          onPress: () => setProtectedTemplate(template),
        }))
      );
    }
  };

  useEffect(() => {
    if (suspend) return;
    if (!template) onStart();
    else if (template) {
      //setActions(findFields(repairVariables(template?.content), data).validFields)
    }
  }, [template, suspend]);

  useEffect(() => {
    if (actions.length) setStep(0);
  }, [actions.length]);

  // Iterate through actions
  const typeToLabel = (type) => {
    switch (type) {
      case 'products':
        return 'Product'; //todo t()
      case 'services':
        return 'Service';
      case 'partners':
        return 'Partner';
      case 'customersB2B':
        return 'B2B Client';
      case 'customersB2P':
        return 'B2C Client';
      default:
        return '';
    }
  };

  const submit = (value) => {
    const action = actions[step];
    if (action.index === values.slice(-1)?.index) return;
    if (action.fieldName === 'CUSTOMER NAME') {
      setClientName(value);
    }

    setValues((prev) => [
      ...prev,
      {
        value,
        index: action.index,
        fieldLength: action.fieldLength,
        specialType: action.specialType,
      },
    ]);
    setStep((p) => p + 1);
  };

  // Action processing: tries to set value, otherwise asks for it and offers some variants
  useEffect(() => {
    if (!template || !actions?.length) {
      return;
    }

    const action = actions[step] || false;
    if (!action) {
      if (template && step !== -2) {
        //todo generateDoc
        setThinking(true);
        createDocument().then((document) => {
          addMessage(
            t('Your document is ready'),
            ASSISTANT,
            null,
            false,
            null,
            false,
            {
              isReady: true,
              showsPreview: true,
              onFinalPreview: () =>
                navigate(`${navigate.path}/documents/${document.id}`),
            }
          );
          setThinking(false);
          setScenario({ name: 'initial', data: { suspend: true } });
        });
        setOptions([]);
        setStep(-2); // Saved
      }
      return;
    }

    if (action?.memorable) {
      if (Object.keys(memory?.memorizedFields).includes(action.fieldName)) {
        submit(memory.memorizedFields[action.fieldName]);
        return;
      }
    } else if (
      action?.type &&
      Object.keys(memory.selectedInstances).includes(action.type) &&
      !action.isNew
    ) {
      submitListItem(action, memory.selectedInstances[action.type]);
      return;
    }

    if (action?.specialType) {
      switch (action.specialType) {
        case 'read key': {
          addMessage(
            template.content
              .slice(
                actions[step].index + action.fieldLength,
                actions[step + 1].index
              )
              .replace(/<br \/>/gi, ' ')
              .replace(/&nbsp;/g, ' ')
              .replace(/\\n/g, ' ')
              .replace(/(<([^>]+)>)|(\\n)/gi, '')
              .replace(/\s+/g, ' ')
          );
          setNextIsSilent(true);
          submit('');
          return;
        }
        case 'provision': {
          addMessage(
            action.question,
            ASSISTANT,
            null,
            false,
            values.filter(({ specialType }) => specialType === 'provision')
              .length + 1
          );
          setOptions([]);
          return;
        }
        case 'price': {
          break;
        }
        case 'total': {
          let total = calculateTotal(values);
          if (total !== 0)
            setOptions([
              {
                label: t('Calculated') + ': ' + total,
                onPress: () => {
                  submit(total);
                  addMessage(total, USER);
                },
              },
            ]);
          addMessage(action.question);
          return;
        }
        case 'photo': {
          const getFiles = async (event) => {
            const file = event.target.files[0];
            addMessage(file.name, USER);
            const base64 = await new Promise((resolve) => {
              const reader = new FileReader();
              reader.onload = () => resolve(reader.result);
              reader.readAsDataURL(file);
            });
            const compressedImg = await resizebase64(base64, '100%', '100%');
            submit(`<img src="${compressedImg}">`);
          };
          const clip = (label) => {
            return (
              <label style={{ cursor: 'pointer', margin: 0, width: '100%' }}>
                {label}
                <PaperClip
                  width="16"
                  stroke="red"
                  style={{ marginLeft: '10px' }}
                />
                <input
                  name="input"
                  type="file"
                  style={{ display: 'none' }}
                  encType="multipart/form-data"
                  accept="image/*"
                  onChange={getFiles}
                />
              </label>
            );
          };
          setOptions([{ label: clip(t('Upload')) }]);
          addMessage(action.question);
          return;
        }
      }
    }

    if (action.value || action.value === '') {
      submit(action.value);
      return;
    }

    const options = action?.list
      ? sortByIdDesc(
          action.list.map((item) => ({
            label: item.name || item.title,
            id: item.id,
            onPress: () => submitListItem(action, item),
          }))
        )
      : [];
    const adders = action.type
      ? action.type.split(',').map((type) => ({
          label: typeToLabel(type),
          type,
          createsNew: true,
        }))
      : [];
    setOptions([...adders, ...options]);

    if (action.question) {
      if (nextIsSilent) setNextIsSilent(false);
      else addMessage(action.question);
    }
  }, [step, data]);

  const submitListItem = (action, item) => {
    setListItem({ ...item });
    const fields = action?.mappingFields;
    const value =
      fields
        .map((field) =>
          field === 'address' ? addressToStr(item[field]) || '' : item[field]
        )
        .join(',') || false;
    if (!value) {
      if (fields.join(',') === 'responsibleforpurchasing') {
        submit(item?.name || '');
        return;
      }
      if (memory.selectedInstances[action.type]?.[fields.join(',')]) {
        if (fields.join(',') === 'address') {
          addMessage(action.missingQuestion);
          setOptions([]);
          setManualState(true);
          return;
        }
        submit(memory.selectedInstances[action.type]?.[fields.join(',')]);
        return;
      }
      addMessage(action.missingQuestion);
      setOptions([]);
      setManualState(true);
    } else {
      submit(value, action.index, action.fieldLength);
      if (
        (action?.type &&
          !Object.keys(memory.selectedInstances).includes(action.type)) ||
        action.isNew
      ) {
        setMemory((prev) => ({
          ...prev,
          selectedInstances: { ...prev.selectedInstances, [action.type]: item },
        }));
        addMessage(value, USER);
      }
    }
  };

  const send = async (text) => {
    addMessage(text, USER);
    if (!template) {
      setThinking(true);
      let foundItems = (await data.templates.getItemsByKeywords([text])) || [];
      setOptions(
        sortByIdDesc(
          foundItems.map((item) => ({
            label: item.title,
            id: item.id,
            onPress: () => setTemplate(item),
          }))
        ) || []
      );
      if (foundItems && foundItems.length > 0)
        addMessage(t('Here are templates I found'));
      else {
        addMessage(t('Sorry, I found nothing'));
        // set initial options if no results
        setOptions(
          sortByIdDesc(
            templates.data.map((template) => ({
              label: template.title,
              id: template.id,
              onPress: () => setTemplate(template),
            }))
          )
        );
      }
      setThinking(false);
      return;
    }
    const action = actions[step];
    if (manualState) {
      submit(text);
      try {
        setMemory((memory) => ({
          //Memorizing missing value
          ...memory,
          selectedInstances: {
            ...memory.selectedInstances,
            [action.type]: {
              ...memory.selectedInstances[action.type],
              [action.mappingFields.join(',')]: text,
            },
          },
        }));
      } catch (e) {
        console.error(e);
      }
      setManualState(false);
      return;
    }
    if (action?.memorable) {
      setMemory((prev) => ({
        ...prev,
        memorizedFields: { ...prev.memorizedFields, [action.fieldName]: text },
      }));
      submit(text);
      return;
    }
    if (action?.specialType) {
      switch (action.specialType) {
        case 'advance': {
          submit(calculateAdvance(text, values));
          return;
        }
      }
    }
    if (action?.list || action?.type) {
      // thus we're searching
      setThinking(true);
      const fields = action.type.split(',').map((type) => data[type]);
      let foundItems = [];

      for (const field of fields) {
        const result = await field.getItemsByKeywords([text]);
        const fetchedItems = result instanceof Array ? result : [];
        foundItems = [...foundItems, ...fetchedItems];
      }
      const adders = action.type
        ? action.type.split(',').map((type) => ({
            label: typeToLabel(type),
            type,
            createsNew: true,
          }))
        : [];

      setOptions([
        ...adders,
        ...foundItems.map((item) => ({
          label: item.name || item.title,
          onPress: () => {
            submitListItem(action, item);
          },
        })),
      ]);
      setThinking(false);
      if (foundItems && foundItems.length > 0)
        addMessage(t('Here is what I found'));
      else {
        addMessage(t('Sorry, I found nothing'));
        const options = action?.list
          ? sortByIdDesc(
              action.list.map((item) => ({
                label: item.name || item.title,
                id: item.id,
                onPress: () => submitListItem(action, item),
              }))
            )
          : [];
        const adders = action.type
          ? action.type.split(',').map((type) => ({
              label: typeToLabel(type),
              type,
              createsNew: true,
            }))
          : [];
        setOptions([...adders, ...options]);
      }
      return;
    }
    if (action?.specialType) {
      // for now
      submit(text, action.index, action.fieldLength);
      return;
    } else if (action) {
      submit(text, action.index, action.fieldLength);
      return;
    }
    // todo search or submit
  };

  const createDocument = async (isPreview = false) => {
    const purpleHighlight = (value) =>
      `<span id="current-field" style="background-color: #8a1d70; color: #ffffff; font-size: 14pt;"><strong>[ ${value} ]</strong></span>`;

    let templateContent = repairVariables(template.content);
    [...values].reverse().forEach((value) => {
      templateContent =
        templateContent.slice(0, value.index) +
        (value.value || '') +
        templateContent.slice(value.index + value.fieldLength);
    });

    if (isPreview) {
      const { fieldName, ...action } = actions[step];
      const _content = templateContent.replace(
        `[${fieldName.split(' ').join('_')}]`,
        purpleHighlight(
          t(
            action.missingQuestion?.replace('?', '') ||
              action.question?.replace('?', '') ||
              'Here you are'
          )
        )
      );
      return _content;
    }

    const listItemPayload = (model = null, type, id) => {
      switch (model) {
        case PARTNER:
          return { partner_ids: [id] };
        case CUSTOMER:
          return type === 0
            ? { business_customer_ids: [id] }
            : { person_customer_ids: [id] };
        case PRODUCT:
          return { product_ids: [id] };
        case SERVICE:
          return { service_ids: [id] };
        case TAG:
          return { tag_ids: [id] };
        default:
          return {};
      }
    };

    const providedListItems = listItemPayload(
      listItem?.model_type,
      listItem?.type,
      listItem?.id
    );

    const response = await data.documents.create({
      title: `${template.title} ${clientName} ${formatDate()}`,
      content: templateContent,
      template_id: template.id,
      ...providedListItems,
    });
    setReady(true);

    return response;
  };

  useEffect(() => {
    if (!templatesAreLoaded || !initialTemplate) return;
    setTemplate(initialTemplate, true);
    setStep(-1);
  }, [templatesAreLoaded, initialTemplate]);

  return {
    messages: [...messages],
    isThinking,
    send,
    step,
    options,
    recorder,
    player,
    isReady,
    context: template ? actions?.[step]?.context || false : 'TEMPLATES',
    getPreview: () => createDocument(true),
  };
};

const calculateTotal = (values) => {
  const extractNumber = (_value) => {
    const value = '' + _value;
    const numRegex = /\d+/;
    const percentRegex = /\d+%/;
    const number = value.match(numRegex)[0];
    const isPercents = percentRegex.test(value);
    const error = !numRegex.test(value);
    return { number, isPercents, error };
  };

  // Getting all the data we work with
  const valuableValues = values.filter(
    ({ specialType }) =>
      specialType === 'price' ||
      specialType === 'quantity' ||
      specialType === 'discount'
  );

  const preparedValues = valuableValues.map(({ specialType, value, error }) => {
    switch (specialType) {
      case 'price':
        return {
          value: extractNumber(value).number,
          type: specialType,
          error: error,
        };
      case 'discount':
        return {
          value: extractNumber(value).number,
          isPercents: extractNumber(value).isPercents,
          type: 'discount',
          error: error,
        };
      case 'quantity':
        return {
          value: extractNumber(value).number,
          type: specialType,
          error: error,
        };
    }
  });

  const pairType = { price: 0, quantity: 0 };
  let pairs = [];
  let subtotal = 0;
  let currentPrice = 0;
  let currentQuantity = 0;
  let discount = { value: 0, isPercents: true };

  preparedValues.forEach(({ value, type, isPercents = false, error }) => {
    if (error) return null;

    if (type === 'price') {
      pairs[currentPrice] = { ...pairs[currentPrice], price: value };
      currentPrice++;
    }
    if (type === 'quantity') {
      pairs[currentQuantity] = { ...pairs[currentQuantity], quantity: value };
      currentQuantity++;
    }
    if (type === 'discount') {
      discount = {
        value,
        isPercents,
      };
    }
  });

  subtotal = pairs.reduce((prev, { price = 0, quantity = 1 }) => {
    return prev + price * quantity;
  }, 0);
  return discount.isPercents
    ? subtotal * (1 - discount.value / 100)
    : subtotal - discount.value;
};

const calculateAdvance = (advance, values) => {
  const total =
    [...values]
      .filter(({ specialType }) => specialType === 'total')
      ?.slice(-1)[0]?.value ||
    calculateTotal(values) ||
    0;
  const numberRegex = /\d+/;
  const percentRegex = /\d+%/;

  if (percentRegex.test('' + advance))
    return total * (1 - ('' + advance).match(numberRegex)[0] / 100);

  return advance; // If no percents
};

export const useAssistantScenarios = ({
  initialTemplate = null,
  initialSearchQuery = null,
  initialScenario = 'initial',
  searchOpener = () => {},
}) => {
  const dialogData = useAssistantDialog();

  const getInitialScenario = () => {
    if (initialSearchQuery && !initialTemplate) return 'search';
    if (initialTemplate && !initialSearchQuery) return 'template';
    return 'initial';
  };

  const [scenario, setScenario] = useState({
    name: getInitialScenario(),
    data: {},
  });

  const baseData = { ...scenario.data, setScenario };
  const supportData = useSupport(
    scenario.name !== 'support',
    dialogData,
    scenario?.data?.supportRequest
  );
  const templateFillingData = useTemplateFilling(
    setScenario,
    scenario?.data?.initialTemplate || initialTemplate,
    scenario.name !== 'template' || scenario?.data?.suspend,
    dialogData
  );
  const searchData = useAssistantSearch(
    scenario?.data?.initialSearchQuery || initialSearchQuery,
    searchOpener,
    scenario.name !== 'search' || scenario?.data?.suspend,
    dialogData
  );
  const initialData = useInitialScenario(
    setScenario,
    searchOpener,
    dialogData,
    scenario.name !== 'initial' || scenario?.data?.suspend
  );

  // eslint-disable-next-line default-case
  switch (scenario.name) {
    case 'support':
      return { ...supportData, ...baseData };
    case 'template':
      return { ...templateFillingData, ...baseData };
    case 'search':
      return { ...searchData, ...baseData };
    case 'initial':
      return { ...initialData, ...baseData };
  }
};

const useAssistantSearch = (
  initialSearchQuery = false,
  searchOpener = (query = '') => {},
  suspend = false,
  dialogData
) => {
  const { t } = useTranslation();
  const [query, setQuery] = useState(initialSearchQuery);
  const { messages, addMessage, recorder, player, clear } = dialogData;
  const [isButtonShown, setButtonShown] = useState(false);

  useEffect(() => {
    setQuery(initialSearchQuery);
  }, [initialSearchQuery]);

  const send = (text = '') => {
    addMessage(text, USER);
    setQuery(text);
  };
  useEffect(() => {
    if (suspend) return;

    if (query !== '') {
      addMessage(
        t('opening_search'),
        ASSISTANT,
        null,
        false,
        null,
        true,
        {
          isReady: true,
          showsResults: true,
          onFinalPreview: () => {
            searchOpener(query);
          },
        }
      );
      // addMessage(t('opening_search'));
      // setTimeout(() => {
      //  // searchOpener(query);
      //  setButtonShown(true);
      // }, 500);
    } else if (!initialSearchQuery) {
      addMessage(t('what_do_you_want_to_find'));
    }
  }, [query, suspend]);

  return {
    messages,
    options: [],
    isThinking: false,
    send,
    recorder,
    getPreview: () => {
      searchOpener(query);
    },
    step: -1,
    isResultsButtonShown: false,
    isReady: false,
    context: '',
    isSearch: true,
  };
};

const useInitialScenario = (
  setScenario = () => {},
  searchOpener = (query = '') => {},
  dialogData,
  suspend = false
) => {
  const { t } = useTranslation();
  const { assistant, templates } = useContext(DataContext);
  const { messages, addMessage, recorder } = dialogData;
  const threadId = useRef(null);
  const [isThinking, setThinking] = useState(false);
  const [options, setOptions] = useState([
    {
      label: t('I want to find something'),
      onPress: () => {
        send(t('I want to find something'));
        // setScenario({ name: "search", data: {} });
      },
    },
    {
      label: t('I want to create a document'),
      onPress: () => {
        send(t('I want to create a document'));
        // setScenario({ name: "template", data: {} });
      },
    },
    {
      label: t('I need technical support'),
      onPress: () => {
        addMessage(t('I_need_technical_support'), USER);
        setScenario({ name: 'support', data: {} });
      },
    },
  ]);

  useEffect(() => {
    if (suspend) return;
    addMessage(
      assistant.data.name + ' ' + t('is_listening._how_can_i_help_you?')
    );
  }, [suspend]);

  const send = (text) => {
    addMessage(text, USER);
    processMessage(text);
    setOptions([]);
  };

  const processMessage = async (value) => {
    const text = value?.trim();
    if (!text) return;

    try {
      setThinking(true);
      const { data } = await POST('/api/AssistantChat/chat', {
        prompt: text,
        messages: [],
        ...(threadId.current ? { threadId: threadId.current } : {}),
      });

      const { response, additionalInformation, ...other } = JSON.parse(data);
      const additionalInfo = additionalInformation || {};
      if (other.threadId) threadId.current = other.threadId;

      if (
        additionalInfo.isUserIntendsToCreateDocument &&
        additionalInfo.templateId
      ) {
        setScenario({
          name: 'template',
          data: {
            initialTemplate: await templates.getItemById(
              additionalInfo.templateId
            ),
          },
        });
      } else if (additionalInfo.isUserIntendsToOpenSearch) {
        const searchQuery = Array.isArray(additionalInfo.searchKeywords)
          ? additionalInfo.searchKeywords.join(' ')
          : additionalInfo;
        addMessage(
          response.replace(/(\[(.*?)\]\()(.+?)(\))/g, '').replace('\n\n', ''),
          ASSISTANT,
          null,
          false,
          null,
          true,
          {
            isReady: true,
            showsResults: true,
            onFinalPreview: () => {
              searchOpener(
                searchQuery,
                additionalInfo.isUserIntendsToOpenSearch,
                additionalInfo.searchKeywords
              );
            },
          }
        );
      } else if (additionalInfo.isUserIntendsToReachSupport) {
        setScenario({
          name: 'support',
          data: {
            supportRequest: additionalInfo.supportRequest,
          },
        });
      } else if (!response) {
        addMessage(
          t('Assistant is temporarily unavailable, please try again later.')
        );
      } else {
        addMessage(response, ASSISTANT, null, false, null, true);
      }

      setThinking(false);
    } catch (error) {
      setThinking(false);
      toast(
        <Info>
          {t(
            'Oops! Something went wrong processing your message. Please try again.'
          )}
        </Info>
      );
    }
  };

  return {
    messages,
    options,
    send,
    recorder,
    getPreview: () => {},
    step: -1,
    context: 'INIT',
    isReady: false,
    isSearch: true,
    isThinking,
  };
};
