import React, { useState } from "react";
import styles from "./style.module.css";
import cn from "classnames";
import TouchableOpacity from "../TouchableOpacity";
import { PulseLoader } from "react-spinners";

const Button = ({
  title,
  disabledTitle,
  waiting,
  onClick = () => { },
  className,
  containerClassName,
  height = "32px",
  width = "100%",
  color = "#B11873",
  thin,
  fontSize = "14px",
  containerStyle,
  underlyingColor = "transparent",
  type,
  textColor = "white",
  loaderSize = 7,
  disabled = false,
  disabledStyles = {},
  transparent,
  noShadow,
  border,
  leftElement = <></>,
  ...rest
}) => {
  // title = typeof title === "string" ? title.toUpperCase() : title;

  return (
    <TouchableOpacity
      className={cn(styles.container, containerClassName)}
      style={{ height, width, ...containerStyle, ...disabled && disabledStyles }}
    >
      {leftElement}
      <button
        className={cn(styles.button, !noShadow && styles.withShadow, className)}
        onClick={onClick}
        style={{
          backgroundColor: transparent ? "inherit" : color,
          color: transparent ? color : textColor,
          fontWeight: thin ? "400" : "500",
          borderRadius: 4,
          marginRight: '1px',
          fontSize,
          ...transparent && border && { border: `2px solid ${color}` }
        }}
        type={type}
        disabled={disabled}
        {...rest}
      >
        {waiting ? <PulseLoader size={loaderSize} color={textColor} /> : disabled && disabledTitle ? disabledTitle : title}
      </button>
    </TouchableOpacity>
  );
};

export default Button;
